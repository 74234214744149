@font-face {
  font-family: Helvetica-Now-Display-lg;
  src: url(../src/assets/fonts/HelveticaNowDisplay-Light.ttf);
}
@font-face {
  font-family: Helvetica-Now-Display-re;
  src: url(../src/assets/fonts/HelveticaNowDisplay-Regular.ttf);
}
@font-face {
  font-family: Helvetica-Now-Display-bd;
  src: url(../src/assets/fonts/HelveticaNowDisplay-Bold.ttf);
}
@font-face {
  font-family: Helvetica-Now-Display-it;
  src: url(../src/assets/fonts/HelveticaNowDisplay-RegIta.ttf);
}
@font-face {
  font-family: Helvetica-Now-Display-it-bd;
  src: url(../src/assets/fonts/HelveticaNowDisplay-BoldIta.ttf);
}
@font-face {
  font-family: Helvetica-Now-Display-it-ebd;
  src: url(../src/assets/fonts/HelveticaNowDisplay-ExtraBold.ttf);
}
body {
  font-family: Helvetica-Now-Display-re, sans-serif;
}
a {
  font-family: Helvetica-Now-Display-re, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Helvetica-Now-Display-it-ebd, sans-serif;
}
img {
  max-width: 100%;
  height: auto;
}
p {
  font-family: Helvetica-Now-Display-re, sans-serif;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  border-color: rgb(235, 168, 60) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
